@import './components/LayoutFooter/layout-footer';

.layout {
    height: 100vh;
    position: relative;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    background-color: #FFFFFF;

    &__header {
        display: flex;
        align-items: center;
        position: sticky;
        top: 0;
        left: 0;
        z-index: 1;
        background-color: #FFFFFF;
        border-bottom: 1px solid #E3E7ED;
        box-shadow: 0px 4px 12px 0px rgba(209, 146, 0, 0.12);
        padding: 16px 108px;
        height: 73px;
    }

    &__logo-link {
        appearance: none;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        margin-left: auto;

        &--no-margin {
            margin-left: 0;
        }
    }

    &__logo {
        height: 20px;
    }

    &__back-button {
        & > svg path {
            fill: currentColor;
        }
    }

    &__reinstall-button {
        margin-left: auto;
    }

    &__content-wrapper {
        display: flex;
        flex-direction: column;
        height: calc(100% - 72px);
        overflow-y: auto;
        overflow-x: hidden;
        background:
            linear-gradient(180deg, #FFF6E0 0%, rgba(255, 243, 214, 0.00) 100%) center / 100% no-repeat,
            linear-gradient(180deg, #FFFCF5 0%, rgba(255, 252, 245, 0.00) 38.33%) center / 100% no-repeat;
        
    }

    &__scrollable {
        height: 100%;

        .simplebar-content {
            height: 100%;
            display: flex;
            flex-direction: column;
        }
    }

    &__content {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        flex: 1;
    }
}
