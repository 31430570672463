.processing {
  display: flex;
  padding: 40px;
  width: 100%;
  height: 100%;
  align-items: center;
  max-width: 464px;
  margin: auto;

  &__content {
    display: flex;
    flex-direction: column;
    gap: 8px;
    align-items: center;
    justify-content: center;
    text-align: center;
    width: 100%;
    background-color: #FFFFFF;
    box-shadow: 0px 4px 16px 0px rgba(124, 92, 20, 0.08);
    border-radius: 16px;
    padding: 24px 32px;
    font-size: 14px;
    position: relative;
    min-height: 340px;
  }

  &__title {
    font-size: 16px;
    font-weight: 500;
    line-height: 28px;
  }

  &__icon {
    width: 32px;
    min-width: 32px;
    height: 32px;

    &--animated {
      animation: spinAnimation 1s linear infinite;
    }

    @keyframes spinAnimation {
      0% {
        transform: rotate(0deg);
      }
      100% {
        transform: rotate(360deg);
      }
    }
  }

  &__button {
    margin-top: 8px;
  }
}
