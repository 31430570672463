.signup {
    display: flex;
    background-color: #FFFFFF;
    height: 100%;

    & > * {
        width: 50%;
    }

    &__left {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        padding: 24px;
        box-shadow: 4px 0px 24px 0px rgba(113, 91, 41, 0.10);
        border-right: 1px solid #E3E7ED;
        gap: 24px;

        & > * {
            display: flex;
            flex: 1;
        }
    }

    &__right {
        background:
            url(../../assets/signup-background.svg) center / auto 100% no-repeat,
            radial-gradient(63.99% 50% at 50% 50%, rgba(255, 253, 250, 0.50) 0%, rgba(255, 250, 240, 0.50) 53.5%, rgba(255, 244, 217, 0.50) 100%);
    }

    &__title {
        margin-top: auto;
        text-align: center;
        font-size: 24px;
        font-weight: 600;
        line-height: 1.5;
        align-items: flex-end;
    }

    &__bottom {
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        gap: 24px;
    }

    &__google-button {
        font-family: 'Roboto', sans-serif;
        padding: 9px 12px;
        gap: 10px;
        line-height: 20px;
        border-radius: 4px;
        width: fit-content;
    
        & svg {
            width: 18px;
            height: 17px;
        }
    }

    &__footer {
        display: flex;
        flex-direction: column;
        gap: 8px;
        align-items: center;
        text-align: center;
        font-size: 10px;
        line-height: 16px;
        font-weight: 500;
    }

    &__link {
        appearance: none;
        background-color: transparent;
        padding: 0;
        border: none;
        font-size: inherit;
        font-weight: inherit;
        font-family: inherit;
        line-height: inherit;
        cursor: pointer;
        display: inline;
        color: $black;
        text-decoration: underline;
        transition: color 150ms ease-in-out;

        &:hover {
            color: #537bff;
        }

        &:active {
            color: #2244b3;
        }
    }

    &__email-link {
        text-decoration: none;
        color: #537bff;
        transition: color 150ms ease-in-out;

        &:hover {
            color: #2244b3;
        }
    }
}
