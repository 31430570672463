.layout-footer {
    display: flex;
    flex-direction: column;
    background-color: #18222F;
    position: relative;

    &__disclaimer {
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;
        padding: 32px;
        background-color: #2C3644;
        box-shadow: 0px 4px 12px 0px rgba(0, 0, 0, 0.50);
        color: #FFFFFF;
        font-size: 20px;
        font-weight: 600;
        line-height: 36px;
    }

    &__slider {
        user-select: none;
        padding: 37px 0 20px;

        & img {
            margin: 0 auto;
        }

        &::before,
        &::after {
            content: '';
            z-index: 1;
            position: absolute;
            top: 0;
            height: 100%;
            width: 50%;
        }

        &::before {
            left: 0;
            background: linear-gradient(90deg, rgba(0, 0, 0, 0.90) 0%, rgba(0, 0, 0, 0.00) 15%);
        }

        &::after {
            right: 0;
            background: linear-gradient(90deg, rgba(0, 0, 0, 0.00) 85%, rgba(0, 0, 0, 0.90) 100%);
        }
    }
}