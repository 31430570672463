@import './components/Features/features';

.pricing {
    display: flex;
    flex-direction: column;
    gap: 16px;
    width: 100%;
    max-width: 824px;
    padding: 40px;
    margin: 0 auto;

    &__content {
        display: flex;
        flex-direction: column;
        gap: 24px;
    }

    &__title {
        font-size: 24px;
        font-weight: 600;
        line-height: 36px;
    }

    &__header {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    &__tabs-wrapper {
        display: flex;
        align-items: center;
        gap: 8px;
        background-color: #FFFFFF;
        border: 1px solid #E3E7ED;
        border-radius: 8px;
        padding: 4px;
    }

    &__tab {
        appearance: none;
        display: inline-flex;
        width: 82px;
        align-items: center;
        justify-content: center;
        text-align: center;
        border: none;
        border-radius: 4px;
        background-color: #FFFFFF;
        padding: 2px 8px;
        font-size: 12px;
        font-weight: 400;
        line-height: 20px;
        transition: color 150ms ease-in-out, background-color 150ms ease-in-out;
        color: $black;
        font-family: inherit;
        cursor: pointer;

        &:hover:not(:disabled) {
            background-color: #f1f4f8;
        }

        &:disabled {
            cursor: default;
            background-color: $black;
            color: #FFFFFF;
        }
    }

    &__plans-wrapper {
        display: flex;
        background-color: #FFFFFF;
        border: 1px solid #E3E7ED;
        border-radius: 16px;
        box-shadow: 0px 4px 16px 0px rgba(124, 92, 20, 0.08);
        position: relative;
    }

    &__plan {
        display: flex;
        flex-direction: column;
        gap: 16px;
        padding: 16px 24px;

        &:nth-child(1) {
            width: 44%;
            border-right: 1px solid #E3E7ED;
        }

        &:nth-child(2) {
            width: 56%;
        }
    }

    &__plan-header {
        display: flex;
        flex-direction: column;
        gap: 4px;
    }

    &__plan-description {
        color: #646F87;
        font-size: 14px;
        font-weight: 500;
    }

    &__plan-price {
        display: flex;
        gap: 4px;
        font-size: 40px;
        font-weight: 500;
        line-height: 60px;
        opacity: 0;
        animation: priceFadeIn 300ms ease-in-out forwards;
    }

    &__plan-price-disclaimer {
        font-size: 12px;
        font-weight: 500;
        line-height: 16px; 
        opacity: 0;
        animation: priceFadeIn 300ms ease-in-out forwards;
    }

    &__currency-sign {
        padding-top: 10px;
        font-size: 20px;
        font-weight: 700;
        line-height: 28px;
    }

    &__discount-disclaimer {
        padding-top: 10px;
        font-size: 14px;
        font-weight: 500;
        line-height: 24px;
        color: #44536A;
    }

    &__plan-button {
        &:disabled {
            border-color: #18222F;
            background-color: #18222F;
            color: #646F87;
        }
    }

    &__spinner-icon {
        animation: spinAnimation 1s linear infinite;

        @keyframes spinAnimation {
            0% {
                transform: rotate(0deg);
            }
            100% {
                transform: rotate(360deg);
            }
        }
    }

    &__plan-features-wrapper {
        display: flex;
        flex-direction: column;
        gap: 8px;
    }

    &__plan-feature-title {
        font-size: 16px;
        font-weight: 500;
        line-height: 28px;
    }

    &__plan-feature {
        display: flex;
        align-items: center;
        font-size: 14px;
        gap: 8px;

        & > svg {
            width: 16px;
            height: 16px;
        }
    }

    @keyframes priceFadeIn {
        to {
            opacity: 1;
        }
    }
}
