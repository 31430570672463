.page-transition {
    height: 100%;
    opacity: 0;
    animation: pageTransition 300ms ease-in-out forwards;
    
    @keyframes pageTransition {
        0% {
            opacity: 0;
        }
        100% {
            opacity: 1;
        }
    }
}
