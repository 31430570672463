@font-face {
  font-family: 'Nexa Bold';
  src: url(./Nexa-Bold.ttf);
}

@font-face {
  font-family: 'Nexa Light';
  src: url('./Nexa-Light.ttf');
}

@font-face {
  font-family: 'Nexa Regular';
  src: url('./NexaRegular.ttf');
}

@font-face {
  font-family: 'Nexa Thin';
  src: url('./NexaThin.ttf');
}

@font-face {
  font-family: 'Nexa Heavy';
  src: url('./NexaHeavy.ttf');
}

@font-face {
  font-family: 'Nexa XBold';
  src: url('./Nexa-XBold.ttf');
}

@font-face {
  font-family: 'Nexa Book';
  src: url('./Nexa-Book.ttf');
}

@font-face {
  font-family: 'Poppins';
  src: local('Poppins'), url('./Poppins-Thin.ttf') format('truetype');
  font-weight: 100;
  font-style: normal;
}

@font-face {
  font-family: 'Poppins';
  src: local('Poppins'), url('./Poppins-ThinItalic.ttf') format('truetype');
  font-weight: 100;
  font-style: italic;
}

@font-face {
  font-family: 'Poppins';
  src: local('Poppins'), url('./Poppins-ExtraLight.ttf') format('truetype');
  font-weight: 200;
  font-style: normal;
}

@font-face {
  font-family: 'Poppins';
  src: local('Poppins'), url('./Poppins-ExtraLightItalic.ttf') format('truetype');
  font-weight: 200;
  font-style: italic;
}

@font-face {
  font-family: 'Poppins';
  src: local('Poppins'), url('./Poppins-Light.ttf') format('truetype');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'Poppins';
  src: local('Poppins'), url('./Poppins-LightItalic.ttf') format('truetype');
  font-weight: 300;
  font-style: italic;
}

@font-face {
  font-family: 'Poppins';
  src: local('Poppins'), url('./Poppins-Regular.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'Poppins';
  src: local('Poppins'), url('./Poppins-Italic.ttf') format('truetype');
  font-weight: 400;
  font-style: italic;
}

@font-face {
  font-family: 'Poppins';
  src: local('Poppins'), url('./Poppins-Medium.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'Poppins';
  src: local('Poppins'), url('./Poppins-MediumItalic.ttf') format('truetype');
  font-weight: 500;
  font-style: italic;
}

@font-face {
  font-family: 'Poppins';
  src: local('Poppins'), url('./Poppins-SemiBold.ttf') format('truetype');
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: 'Poppins';
  src: local('Poppins'), url('./Poppins-SemiBoldItalic.ttf') format('truetype');
  font-weight: 600;
  font-style: italic;
}

@font-face {
  font-family: 'Poppins';
  src: local('Poppins'), url('./Poppins-Bold.ttf') format('truetype');
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: 'Poppins';
  src: local('Poppins'), url('./Poppins-BoldItalic.ttf') format('truetype');
  font-weight: 700;
  font-style: italic;
}

@font-face {
  font-family: 'Poppins';
  src: local('Poppins'), url('./Poppins-ExtraBold.ttf') format('truetype');
  font-weight: 800;
  font-style: normal;
}

@font-face {
  font-family: 'Poppins';
  src: local('Poppins'), url('./Poppins-ExtraBoldItalic.ttf') format('truetype');
  font-weight: 800;
  font-style: italic;
}

@font-face {
  font-family: 'Poppins';
  src: local('Poppins'), url('./Poppins-Black.ttf') format('truetype');
  font-weight: 900;
  font-style: normal;
}

@font-face {
  font-family: 'Poppins';
  src: local('Poppins'), url('./Poppins-BlackItalic.ttf') format('truetype');
  font-weight: 900;
  font-style: italic;
}

@font-face {
  font-family: 'Roboto';
  src: local('Roboto'), url('./Roboto-Medium.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'Roboto';
  src: local('Roboto'), url('./Roboto-MediumItalic.ttf') format('truetype');
  font-weight: 500;
  font-style: italic;
}
