@import './components/CheckoutForm/checkout-form';

.payment-details {
  display: flex;
  padding: 40px;
  width: 100%;
  max-width: 920px;
  margin: auto;
  height: 100%;
  align-items: center;

  &__content {
    display: flex;
    width: 100%;
    background-color: #FFFFFF;
    border: 1px solid #E3E7ED;
    box-shadow: 0px 4px 16px 0px rgba(124, 92, 20, 0.08);
    border-radius: 16px;
    position: relative;
  }

  &__content-section {
    display: flex;
    flex-direction: column;
    width: 50%;
    gap: 32px;
    padding: 32px 32px 40px 32px;

    &:first-child {
      border-right: 1px solid #E3E7ED;
    }
  }

  &__title {
    display: flex;
    align-items: center;
    gap: 24px;
    font-size: 24px;
    font-weight: 600;
    line-height: 36px; 
  }

  &__trial-disclaimer {
    position: relative;
    font-weight: 500;

    &::before {
      content: '';
      top: 50%;
      left: -12px;
      transform: translateY(-50%);
      position: absolute;
      height: 24px;
      border-right: 2px solid #E3E7ED;
    }
  }

  &__subscription-info {
    display: flex;
    flex-direction: column;
    gap: 8px;
    font-size: 14px;
    font-weight: 500;
  }

  &__subscription-info-item {
    display: flex;
    align-items: center;
    gap: 4px;
    min-height: 32px;
  }

  &__subscription-price {
    display: flex;
    flex-direction: column;
    padding: 4px 0;
    gap: 4px;
  }

  &__subscription-price-divider {
    width: 100%;
    border-bottom: 1px solid #E3E7ED;
  }

  &__subscription-price-row {
    display: flex;
    align-items: center;
    padding: 4px 0;
    gap: 4px;
    font-size: 14px;
    color: #646F87;

    & > :last-child {
      font-weight: 500;
      color: $black;
      margin-left: auto;
    }

    &--total {
      color: $black;
      font-weight: 500;
    }

    &--discount {
      & > :last-child {
        color: #646F87;
      }
    }
  }

  &__footer {
    display: flex;
    align-items: center;
    padding: 12px 0px;
    margin-top: auto;
    gap: 8px;
    justify-content: center;
    font-size: 12px;
    font-weight: 500;
    line-height: 16px;
    color: #646F87;
  }

  &__stripe-label-wrapper {
    display: flex;
    align-items: center;
    gap: 4px;

    & svg {
      width: 36px;
    }
  }

  &__links-wrapper {
    display: flex;
    align-items: center;
    gap: 8px;
    padding: 0 4px;
  }

  &__link {
    text-decoration: underline;
    transition: color 150ms ease-in-out;

    &:visited {
      color: inherit;
    }

    &:hover {
      color: #537bff;
    }

    &:active {
      color: #2244b3;
    }
  }

  &__subscription-cycle-select {
    margin-left: 4px;

    & .select__dropdown {
      width: 102px;
    }
  }

  &__users-count-select {
    margin-left: 4px;

    & .select__dropdown {
      width: 54px;
    }
  }
}
