.uninstall-extension {
  display: flex;
  padding: 40px;
  width: 100%;
  height: 100%;
  align-items: center;
  max-width: 580px;
  margin: auto;

  &__content {
    display: flex;
    flex-direction: column;
    gap: 16px;
    align-items: center;
    text-align: center;
    width: 100%;
    background-color: #FFFFFF;
    box-shadow: 0px 4px 16px 0px rgba(0, 0, 0, 0.05);
    border-radius: 16px;
    padding: 64px 80px;
    font-size: 14px;
    position: relative;
  }

  &__logo {
    width: 40px;
    height: 40px;
  }

  &__title {
    font-size: 16px;
    font-weight: 500;
    line-height: 28px;
  }

  &__text {
    display: inline-flex;
    align-items: center;
    gap: 4px;
  }

  &__link {
    display: inline-flex;
    align-items: center;
    gap: 4px;
    appearance: none;
    background-color: transparent;
    padding: 0;
    border: none;
    font-size: inherit;
    font-weight: inherit;
    font-family: inherit;
    line-height: inherit;
    cursor: pointer;
    color: $black;
    text-decoration: underline;
    transition: color 150ms ease-in-out;

    & > svg {
      width: 16px;
      min-width: 16px;
      height: 16px;

      & path {
        transition: fill 150ms ease-in-out;
        fill: $black;
      }
    }

    &:hover {
      color: #537bff;

      & > svg path {
        fill: #537bff;
      }
    }

    &:active {
      color: #2244b3;

      & > svg path {
        fill: #2244b3;
      }
    }

    &--blue {
      font-weight: 500;
      color: #537bff;

      & > svg path {
        fill: #537bff;
      }

      &:hover {
        color: #2244b3;

        & > svg path {
          fill: #2244b3;
        }
      }
    }
  }
}
