.checkout-form {
    display: flex;
    flex-direction: column;
    gap: 32px;
    flex-grow: 1;

    &__form {
        display: flex;
        flex-direction: column;
        gap: 16px;
        min-height: 246px;

        &--trial {
            min-height: 338px;
        }
    }

    &__trial-disclaimer {
        display: flex;
        flex-direction: column;
        gap: 4px;
        margin-top: auto;
    }

    &__trial-disclaimer-item {
        display: flex;
        align-items: center;
        padding: 4px 0;
        gap: 4px;
        font-size: 16px;
        font-weight: 500;
        line-height: 28px;

        &:first-child > :last-child {
            color: #646F87;
        }

        & > :last-child {
            margin-left: auto;
        }
    }

    &__button-wrapper {
        display: flex;
        flex-direction: column;
        margin-top: auto;
    }

    &__button {
        width: 100%;

        &:disabled.checkout-form__button--disabled {
            border-color: #18222F;
            background-color: #18222F;
            color: #646F87;
        }

        &:disabled:not(.checkout-form__button--disabled) {
            border-color: #18222F;
            background-color: #18222F;
            color: #FFFFFF;
        }

        &.checkout-form__button--success:disabled {
            border-color: #10B981;
            background-color: #10B981;
            color: #FFFFFF;
        }
    }

    &__button-spinner-icon {
        animation: spinAnimation 1s linear infinite;

        @keyframes spinAnimation {
            0% {
                transform: rotate(0deg);
            }
            100% {
                transform: rotate(360deg);
            }
        }
    }

    &__error {
        display: flex;
        text-align: center;
        gap: 4px;
        font-size: 12px;
        font-weight: 500;
        line-height: 16px;
        padding: 0;
        color: #F0384A;
        margin-top: 0;
        overflow: hidden;
        max-height: 0;
        opacity: 0;
        transition:
            max-height 150ms ease-in-out,
            opacity 150ms ease-in-out,
            padding 150ms ease-in-out,
            margin-top 150ms ease-in-out;

        & > svg {
            width: 16px;
            min-width: 16px;
            height: 16px;
        }

        &--visible {
            max-height: 48px;
            margin-top: 16px;
            padding: 8px 0;
            opacity: 1;
        }
    }
}
