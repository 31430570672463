.features {
    display: flex;
    flex-direction: column;

    &__button {
        appearance: none;
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;
        gap: 4px;
        border: none;
        background-color: transparent;
        font-size: 14px;
        font-weight: 500;
        line-height: 24px;
        transition: color 150ms ease-in-out;
        color: $black;
        font-family: inherit;
        width: fit-content;
        margin: 0 auto;
        padding: 8px 12px;
        cursor: pointer;

        & > svg {
            width: 16px;
            height: 16px;

            & path {
                transition: fill 150ms ease-in-out;
                fill: $black;
            }
        }

        &:hover {
            color: $black;

            & > svg path {
                fill: $black;
            }
        }

        &--active {
            color: #646F87;

            & > svg {
                transform: rotate(180deg);

                & path {
                    fill: #646F87;
                }
            }
        }
    }

    &__table-wrapper {
        transition: all 300ms ease-in-out;
        max-height: 1500px;
        padding-top: 16px;

        &--hidden {
            max-height: 0;
            padding-top: 0;
            opacity: 0;
        }
    }

    &__table {
        display: flex;
        flex-direction: column;
        overflow: hidden;
        border-radius: 16px;
        border: 1px solid #E3E7ED;
        background: #FFFFFF;
        box-shadow: 0px 4px 16px 0px rgba(124, 92, 20, 0.08);
        max-height: inherit;
    }

    &__row {
        display: flex;
        align-items: center;

        &:not(:last-child) {
            border-bottom: 1px solid #E3E7ED;
        }

        & > *:not(:last-child) {
            border-right: 1px solid #E3E7ED;
        }

        & > :nth-child(1) {
            width: 44%;
        }

        & > :nth-child(2),
        & > :nth-child(3) {
            width: 28%;
            justify-content: center;
        }

        &--header,
        &--subheader {
            .features__cell {
                font-size: 16px;
                font-weight: 600;
                line-height: 28px;
            }
        }

        &--header {
            .features__cell {
                padding: 16px 24px;
            }
        }

        &--subheader {
            background-color: #FFFCF5;

            .features__cell {
                padding: 8px 16px;
            }
        }
    }

    &__cell {
        display: flex;
        align-items: center;
        gap: 4px;
        font-size: 14px;
        font-weight: 400;
        padding: 4px 16px;

        &--no-tooltip {
            padding-left: 32px;
        }
    }
}