.button {
    appearance: none;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    gap: 8px;
    padding: 11px 15px;
    border-radius: 8px;
    border: 1px solid #18222F;
    background-color: #18222F;
    color: #FFFFFF;
    font-family: inherit;
    font-size: 16px;
    line-height: 24px;
    font-weight: 500;
    min-height: 48px;
    white-space: nowrap;
    cursor: pointer;
    transition:
        background-color 150ms ease-in-out,
        border-color 150ms ease-in-out,
        color 150ms ease-in-out,
        width 150ms ease-in-out;

    & > svg {
        width: 20px;
        min-width: 20px;
        height: 20px;
    }

    &:disabled {
        cursor: default;
        background-color: #f1f4f8;
        border-color: #f1f4f8;
        color: #95a1b6;
    }

    &:hover:not(:disabled) {
        background-color: $black;
        border-color: $black;
    }

    &:active:not(:disabled) {
        background-color: $black;
        border-color: #44536a;
    }

    &--secondary {
        background-color: #FFFFFF;
        border-color: #E3E7ED;
        color: $black;

        &:disabled {
            background-color: #FFFFFF;
            border-color: #E3E7ED;
        }

        &:hover:not(:disabled) {
            background-color: #F8FAFC;
            border-color: #E3E7ED;
        }

        &:active:not(:disabled) {
            background-color: #f1f4f8;
            border-color: #E3E7ED;
        }
    }

    &--primary {
        background-color: #537BFF;
        border-color: #537BFF;
        color: #FFFFFF;

        &:disabled {
            background-color: #537BFF;
            border-color: #537BFF;
        }

        &:hover:not(:disabled) {
            background-color: #2244B3;
            border-color: #2244B3;
        }

        &:active:not(:disabled) {
            background-color: #2244B3;
            border-color: #2244B3;
        }
    }

    &--danger {
        background-color: #F0384A;
        border-color: #F0384A;
        color: #FFFFFF;

        &:disabled {
            background-color: #F0384A;
            border-color: #F0384A;
            color: #FFFFFF;
            opacity: 0.5;
        }

        &:hover:not(:disabled) {
            background-color: #F0384A;
            border-color: #F0384A;
        }
  
        &:active:not(:disabled) {
            background-color: #F0384A;
            border-color: #F0384A;
        }
    }

    &--transparent {
        background-color: transparent;
        border-color: #E3E7ED;
        color: $black;

        &:disabled {
            background-color: transparent;
            border-color: #E3E7ED;
        }

        &:hover:not(:disabled) {
            background-color: #F8FAFC;
            border-color: #E3E7ED;
        }

        &:active:not(:disabled) {
            background-color: #f1f4f8;
            border-color: #E3E7ED;
        }
    }

    &--error {
        background-color: #FFF5F6;
        border-color: #FFF5F6;
        color: #B40D1E;

        &:disabled {
            background-color: #FFF5F6;
            border-color: #FFF5F6;
            color: #B40D1E;
        }

        &:hover:not(:disabled) {
            background-color: #FFF5F6;
            border-color: #FFF5F6;
        }
  
        &:active:not(:disabled) {
            background-color: #FFF5F6;
            border-color: #FFF5F6;
        }
    }

    &--success {
        background-color: #F6FEFA;
        border-color: #F6FEFA;
        color: #10B981;

        &:disabled {
            background-color: #F6FEFA;
            border-color: #F6FEFA;
            color: #10B981;
        }

        &:hover:not(:disabled) {
            background-color: #F6FEFA;
            border-color: #F6FEFA;
        }
  
        &:active:not(:disabled) {
            background-color: #F6FEFA;
            border-color: #F6FEFA;
        }
    }

    &--xs {
        gap: 4px;
        padding: 7px;
        font-size: 12px;
        line-height: 16px;
        min-height: 32px;

        & > svg {
            width: 16px;
            min-width: 16px;
            height: 16px;
        }
    }

    &--s {
        gap: 4px;
        padding: 7px 11px;
        font-size: 14px;
        min-height: 40px;

        & > svg {
            width: 16px;
            min-width: 16px;
            height: 16px;
        }
    }
}
