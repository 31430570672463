.background {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 1440px;
    max-width: 100vw;
    overflow: hidden;
    height: auto;
    z-index: -1;

    &__icon {
        width: 1440px;
        height: auto;
    }
}
