$black: #070B12;
$blue-primary: #29335c;
$blue-secondary: #394577;
$gray-primary: #f2f7fa;
$gray-secondary: #dadfff;
$yellow-primary: #f3cb3d;
$red-primary: #f26065;

$fadeInContent: animationFadeIn 0.4s cubic-bezier(0.27, 0.5, 0.75, 1) 0s;

@keyframes animationFadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@mixin userSelectDisable {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

@mixin userDragDisable {
  -webkit-user-drag: none;
  -khtml-user-drag: none;
  -moz-user-drag: none;
  -o-user-drag: none;
  user-drag: none;
}
