.select {
    position: relative;

    &__field {
        appearance: none;
        display: inline-flex;
        width: 100%;
        padding: 7px;
        border: 1px solid #E3E7ED;
        border-radius: 4px;
        background-color: #FFFFFF;
        color: $black;
        font-size: 12px;
        font-weight: 500;
        line-height: 16px;
        text-align: left;
        justify-content: space-between;
        font-family: inherit;
        cursor: pointer;

        &:focus {
            outline: none;
            border-color: #537BFF;
        }

        & .select__text {
            color: #95a1b6;
        }

        &:disabled {
            cursor: default;
            background-color: #f1f4f8;
            color: #95a1b6;
        }
    }

    &__text {
        width: calc(100% - 20px);
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        align-items: center;
        gap: 8px;
        color: $black;

        & svg {
            width: 16px;
            min-width: 16px;
            height: 16px;
        }
    }

    &__field-icon {
        width: 16px;
        height: 16px;
    }

    &__dropdown {
        position: absolute;
        z-index: 1000;
        top: calc(100% + 8px);
        left: 0;
        width: 100%;
        border-radius: 4px;
        background-color: #FFFFFF;
        box-shadow: 0px 4px 8px 0px rgba(78, 114, 157, 0.12);
        opacity: 0;
        visibility: hidden;
        transition: opacity 150ms ease-in-out, visibility 0ms 150ms;
        max-height: 324px;

        & button {
            cursor: pointer;

            &:not(:disabled):hover, &:not(:disabled):focus {
                background-color: #F8FAFC;
            }

            &:disabled {
                cursor: default;
            }
        }
    }

    &__scrollable {
        height: 100%;
        max-height: 324px;

        .simplebar-content {
            display: flex;
            flex-direction: column;
            gap: 4px;
        }
    }

    &__option {
        appearance: none;
        display: inline-flex;
        align-items: center;
        text-align: left;
        gap: 8px;
        border: none;
        padding: 4px 8px;
        background-color: transparent;
        font-size: 14px;
        line-height: 24px;
        font-family: inherit;

        &--hidden {
            display: none;
        }
    }

    &__checked-icon {
        min-width: 16px;
        width: 16px;
        height: 16px;
    }

    &--expanded {
        & .select__field-icon  {
            transform: rotate(180deg);
        }

        & .select__dropdown {
            opacity: 1;
            visibility: visible;
            transition: opacity 150ms ease-in-out;
        }
    }

    &--selected {
        & .select__field:not(:disabled) .select__text {
            color: $black;
        }
    }
}
