.sound-wave-tooltip {
    display: flex;
    align-items: center;
    gap: 4px;

    &__icon {
        width: 12px;
        height: 12px;
    }

    &__content-wrapper {
        position: fixed;
        top: 0;
        left: 0;
        z-index: 1000002;
        width: 280px;
        animation: openAnimation 150ms ease-in-out;

        &--closing {
            animation: closeAnimation 150ms ease-in-out forwards;
        }

        &--top {
            .sound-wave-tooltip__arrow-icon {
                bottom: 0;
                left: 50%;
                transform: translate(-50%, 100%);
            }
        }
    
        &--top-start {
            .sound-wave-tooltip__arrow-icon {
                bottom: 0;
                left: 16px;
                transform: translate(0, 100%);
            }
        }
    
        &--top-end {
            .sound-wave-tooltip__arrow-icon {
                bottom: 0;
                right: 15px;
                transform: translate(0, 100%);
            }
        }
    
        &--right {
            .sound-wave-tooltip__arrow-icon {
                top: 50%;
                left: 0;
                transform: translate(-100%, -50%);
                filter: drop-shadow(-2px 1px 1px rgba(71, 107, 132, 0.15));
            }
        }
    
        &--right-start {
            .sound-wave-tooltip__arrow-icon {
                top: 9px;
                left: 0;
                transform: translate(-100%, 0);
                filter: drop-shadow(-2px 1px 1px rgba(71, 107, 132, 0.15));
            }
        }
    
        &--right-end {
            .sound-wave-tooltip__arrow-icon {
                bottom: 9px;
                left: 0;
                transform: translate(-100%, 0);
                filter: drop-shadow(-2px 1px 1px rgba(71, 107, 132, 0.15));
            }
        }
    
        &--bottom {
            .sound-wave-tooltip__arrow-icon {
                top: 0;
                left: 50%;
                transform: translate(-50%, -100%);
                filter: none;
            }
        }
    
        &--bottom-start {
            .sound-wave-tooltip__arrow-icon {
                top: 0;
                left: 15px;
                transform: translate(0, -100%);
                filter: none;
            }
        }
    
        &--bottom-end {
            .sound-wave-tooltip__arrow-icon {
                top: 0;
                right: 15px;
                transform: translate(0, -100%);
                filter: none;
            }
        }
    
        &--left {
            .sound-wave-tooltip__arrow-icon {
                top: 50%;
                right: 0;
                transform: translate(100%, -50%);
                filter: drop-shadow(2px 1px 1px rgba(71, 107, 132, 0.15));
            }
        }
    
        &--left-start {
            .sound-wave-tooltip__arrow-icon {
                top: 9px;
                right: 0;
                transform: translate(100%, 0);
                filter: drop-shadow(2px 1px 1px rgba(71, 107, 132, 0.15));
            }
        }
    
        &--left-end {
            .sound-wave-tooltip__arrow-icon {
                bottom: 9px;
                right: 0;
                transform: translate(100%, 0);
                filter: drop-shadow(2px 1px 1px rgba(71, 107, 132, 0.15));
            }
        }

        &--simplified {
            .sound-wave-tooltip__arrow-icon {
                display: none;
            }
    
            .sound-wave-tooltip__content {
                padding: 4px 8px;
                background-color: rgba(255, 255, 255, 0.85);
            }
        }

        &--dark {
            .sound-wave-tooltip__content {
                background: #18222F;
                border: 1px solid #44536a;
                color: #FFFFFF;
                box-shadow: none;

                &--simplified {
                    background-color: rgba(3, 11, 26, 0.85);
                }
            }

            .sound-wave-tooltip__arrow-icon {
                filter: none;
            }
        }

        &--m {
            .sound-wave-tooltip__content {
                padding: 16px;
                border-radius: 12px;
            }

            &.sound-wave-tooltip__content-wrapper--simplified {
                .sound-wave-tooltip__content {
                    background-color: #FFFFFF;
                }
            }

            &.sound-wave-tooltip__content-wrapper--simplified.sound-wave-tooltip__content-wrapper--dark {
                .sound-wave-tooltip__content {
                    background-color: #18222F;
                }
            }
        }

        @keyframes openAnimation {
            from {
                opacity: 0;
            }
            to {
                opacity: 1;
            }
        }
    
        @keyframes closeAnimation {
            from {
                opacity: 1;
            }
            to {
                opacity: 0;
            }
        }
    }

    &__content {
        position: relative;
        text-transform: none;
        color: $black;
        display: flex;
        flex-direction: column;
        gap: 4px;
        padding: 8px 16px;
        border-radius: 8px;
        border: 1px solid #E3E7ED;
        background-color: #FFFFFF;
        box-shadow: 0px 1px 2px 0px rgba(71, 107, 132, 0.30), 0px 2px 6px 0px rgba(71, 107, 132, 0.15);
    }

    &__text {
        font-size: 12px;
        line-height: 20px;
    }

    &__title {
        font-size: 14px;
        font-weight: 500;
        line-height: 24px;
    }

    &__action {
        appearance: none;
        padding: 0;
        border: none;
        background-color: transparent;
        color: #537BFF;
        font-size: 12px;
        font-weight: 500;
        line-height: 16px;
        width: max-content;
        transition: color 150ms ease-in-out;
        cursor: pointer;

        &:hover {
            background-color: transparent;
            color: #325CE6;
        }
    }

    &__text, &__title {
        a, button {
            appearance: none;
            padding: 0;
            border: none;
            background-color: transparent;
            color: #537BFF;
            text-decoration: none;
            transition: color 150ms ease-in-out;
            cursor: pointer;

            &:hover {
                background-color: transparent;
                color: #325CE6;
                text-decoration: none;
            }
        }
    }

    &__arrow-icon {
        position: absolute;
        filter: drop-shadow(0px 2px 1px rgba(71, 107, 132, 0.30));
    }
}
